// src/features/widgets/components/TopDonateWidget.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSubscription } from "react-stomp-hooks";

const TopDonateWidget = ({ userId, stompClient }) => {
    const [topDonators, setTopDonators] = useState([]);
    const [settings, setSettings] = useState(null);

    // Pobierz dane widgetu i ustawienia
    const fetchSettings = async () => {
        try {
            const settingsResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/widget/TOP_DONATE`
            );
            setSettings(settingsResponse.data.settings || {});
        } catch (error) {
            console.error("Error fetching widget settings:", error);
        }
    };

    const fetchWidgetData = async (topEntriesSize) => {
        try {
            const dataResponse = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/widget/top/${topEntriesSize}`
            );
            setTopDonators(dataResponse.data.widgetsData.TOP_DONATE || []);
        } catch (error) {
            console.error("Error fetching widget data:", error);
        }
    };

    useEffect(() => {
        // Najpierw pobierz ustawienia, a następnie dane
        const initializeWidget = async () => {
            await fetchSettings(); // Pobierz ustawienia
        };
        initializeWidget();
    }, [userId]);

    useEffect(() => {
        if (settings?.topEntriesSize) {
            fetchWidgetData(settings.topEntriesSize); // Pobierz dane według liczby wierszy
        }
    }, [settings, userId]);

    // Obsługa WebSocketów
    useSubscription("/users/queue/messages", () => {
        if (settings?.topEntriesSize) {
            fetchWidgetData(settings.topEntriesSize); // Odśwież dane po wiadomości
        }
    });

    if (!settings) {
        return <div>Loading widget...</div>;
    }

    // Wyciąganie stylów z settings
    const {
        fontSize = "16px",
        fontColor = "#ffffff",
        fontStyle = "Arial",
        fontOutlineColor = "#000000",
        fontOutlineSize = "1px",
        rawSpacing = "10",
    } = settings;

    const multipliedSpacing = Number(rawSpacing) * 10;

    const containerStyle = {
        display: "flex",
        flexDirection: "column",
        gap: `${multipliedSpacing}px`,
    };

    const itemStyle = {
        fontSize: `${fontSize}px`,
        color: fontColor,
        fontFamily: fontStyle,
        WebkitTextStrokeColor: fontOutlineColor,
        WebkitTextStrokeWidth: `${fontOutlineSize}px`,
    };

    return (
        <div className="top-donate-widget" style={{ padding: "10px", borderRadius: "8px" }}>
            <ul style={containerStyle}>
                {topDonators.map((donator, index) => (
                    <li key={index} style={itemStyle}>
                        {index + 1}. {donator.name.substring(0, 10)} - {donator.amount} zł
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TopDonateWidget;
