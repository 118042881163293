// src/features/widgets/components/ProgressBarWidget.js
import React, { useEffect, useState } from "react";
import axios from "axios";
import './ProgressBarWidget.css';

const ProgressBarWidget = ({ userId, progressBarId, stompClient }) => {
    const [settings, setSettings] = useState(null);

    // Pobierz ustawienia widgetu
    const fetchWidgetSettings = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/widgets/PROGRESS/${progressBarId}`
            );
            setSettings(response.data.settings || {});
        } catch (error) {
            console.error("Error fetching progress bar widget settings:", error);
        }
    };

    // Pobierz dane na początkowe załadowanie
    useEffect(() => {
        fetchWidgetSettings();
    }, [userId, progressBarId]);

    // Nasłuchiwanie na wiadomości WebSocket przy użyciu stompClient
    useEffect(() => {
        if (!stompClient) return;

        const subscription = stompClient.subscribe(
            "/users/queue/messages",
            (message) => {
                const parsedMessage = JSON.parse(message.body);
                console.log("WebSocket message received in ProgressBarWidget:", parsedMessage);

                fetchWidgetSettings();
            }
        );

        // Wyrejestrowanie subskrypcji po odmontowaniu komponentu
        return () => subscription.unsubscribe();
    }, [stompClient, progressBarId]);

    if (!settings) {
        return <div>Loading progress bar...</div>;
    }

    const {
        targetName = "Target",
        targetAmount = 0,
        targetColorBackground = "black",
        targetColorFont = "black",
        icon = "",
        currentAmount = 0,
    } = settings;

    const progress = parseFloat(currentAmount);
    const target = parseFloat(targetAmount);
    const progressPercent = target ? Math.min((progress * 100) / target, 100) : 0;

    return (
        <div className="progress-container">
            <div className="progress-information-header">
                <p className="header" style={{color: targetColorFont}}>{targetName}</p>
            </div>
            <div className="progress-bar">
                <div
                    className="js-completed-bar completed-bar"
                    style={{
                        width: `${progressPercent}%`,
                        opacity: 1,
                        background: targetColorBackground || "linear-gradient(to left, #ed1e28, #fe6d62)",
                    }}
                    data-complete={progressPercent}
                >
                    <div className="completed-bar__dollars">
                        {[...Array(9)].map((_, i) => (
                            <div className="dollar" key={i}>$</div>
                        ))}
                    </div>
                    {icon && (
                        <img className="completed-bar__truck" src={icon} alt="Progress Icon" width="60px" />
                    )}
                </div>
            </div>
            <div className="progress-information">
                <p className="text-colour--faded-60" style={{color: targetColorFont}}>0zł</p>
                <p style={{color: targetColorFont}}>
                    {progress.toFixed(2)}zł [{progressPercent.toFixed(2)}% | 100%]
                </p>
                <p className="text-colour--primary-red--80" style={{color: targetColorFont}}>{target.toFixed(2)}zł</p>
            </div>
        </div>
    );
};

export default ProgressBarWidget;
