// src/features/widgets/types/WidgetTypes.js
import TextWidget from "../components/TextWidget";
import TopDonateWidget from "../components/TopDonateWidget";
import ProgressBarWidget from "../components/ProgressBarWidget";

export const WidgetMap = {
    TEXT: TextWidget,
    TOP_DONATE: TopDonateWidget,
    PROGRESS: ProgressBarWidget
};
