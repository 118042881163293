import React from "react";
import { useStompClient } from "react-stomp-hooks";
import { WidgetMap } from "../widgets/types/WidgetTypes";
import { useURLParams } from "../../hooks/useURLParams";

const SubscribingComponent = () => {
    const stompClient = useStompClient();
    const { userId, widgetType, progressBarId} = useURLParams();

    if (widgetType === "PROGRESS" && !progressBarId) {
        return (
            <div>
                <h2>Missing Progress Bar ID</h2>
                <p>For PROGRESS widgets, the parameter "progressBarId" is required.</p>
            </div>
        );
    }

    const WidgetComponent = WidgetMap[widgetType];

    if (!WidgetComponent) {
        return <div>Unsupported widget type: {widgetType}</div>;
    }

    return (
        <WidgetComponent
            stompClient={stompClient}
            userId={userId}
            progressBarId={progressBarId}
        />
    );
};

export default SubscribingComponent;
