import { useMemo } from "react";

export const useURLParams = () => {
    return useMemo(() => {
        const params = new URLSearchParams(window.location.search);
        const username = params.get("username");
        const widgetType = params.get("widgetType");
        const progressBarId = params.get("progressBarId");
        return {
            userId: username,
            widgetType,
            progressBarId,
        };
    }, []);
};
