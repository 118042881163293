// src/features/widgets/components/TextWidget.js
import React, { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { onEndSound, playAudio } from "../utils/audioUtils";
import { getStyle } from "../../../common/utils/styleUtils";
import axios from "axios";
import {useSubscription} from "react-stomp-hooks";

const TextWidget = ({ stompClient, userId }) => {
    const [isVisible, setVisible] = useState(false);
    const [settings, setSettings] = useState(null);
    const [incomingMessage, setIncomingMessage] = useState(null);

    useEffect(() => {
        const fetchWidgetSettings = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/users/${userId}/widget/TEXT`
                );
                setSettings(response.data.settings || {});
            } catch (error) {
                console.error("Error fetching widget settings:", error);
            }
        };

        fetchWidgetSettings();
    }, [userId]);

    // Subskrybuj wiadomości z WebSocketu
    useSubscription("/users/queue/messages", (message) => {
        const parsedMessage = JSON.parse(message.body);
        console.log("Received message:", parsedMessage);

        if (parsedMessage.settings.type !== 'TEXT') {
            console.warn(`Ignored message of type ${parsedMessage.settings.type}`);
            return;
        }

        setSettings(parsedMessage.settings || {});
        setIncomingMessage(parsedMessage);

        stompClient.publish({
            destination: "/app/ack",
            body: JSON.stringify(parsedMessage.paymentId),
        });
    });

    useEffect(() => {
        const handleAudioPlayback = async () => {
            // Sprawdź, czy data i paymentId są dostępne
            if (!incomingMessage || !incomingMessage.paymentId) {
                console.warn("Data or paymentId is missing:", incomingMessage);
                return;
            }

            try {
                await playAudio(incomingMessage.paymentId, () => {
                    onEndSound({
                        userId,
                        paymentId: incomingMessage.paymentId,
                        stompClient,
                        setVisible,
                    });
                }, stompClient);
                setVisible(true);
            } catch (error) {
                console.error("Error during audio playback:", error);
            }
        };

        handleAudioPlayback();
    }, [userId, stompClient, incomingMessage]);

    console.log("TextWidget settings:", settings);

    if (!isVisible) return null;

    return (
        <Animated
            animationIn="lightSpeedIn"
            animationOut="zoomOutDown"
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={isVisible}
        >
            <div>
                <span style={getStyle(settings, "header")}>
                    {incomingMessage.data.name} wpłacił {incomingMessage.data.amount} zł
                </span>
            </div>
            <div>
                <span style={getStyle(settings, "message")}>
                    {incomingMessage.data.message}
                </span>
            </div>
        </Animated>
    );
};

export default TextWidget;
